
import Vue from "vue";
import LayoutTopMenu from "@/layouts/LayoutTopMenu.vue";

const Component = Vue.extend({
  created(): void {
    this.$emit("update:layout", LayoutTopMenu);
  }
});
export default Component;
